import { head } from 'lodash';
import React, { useEffect, useState } from 'react';
import FlipMove from 'react-flip-move';

import { ReactComponent as EnergyLight } from '../assets/images/mercedes/icons/energy-1.svg';
import { ReactComponent as EnergyRegular } from '../assets/images/mercedes/icons/energy-2.svg';
import { ReactComponent as EnergyBold } from '../assets/images/mercedes/icons/energy-3.svg';
import { ReactComponent as FuelLight } from '../assets/images/mercedes/icons/fuel-1.svg';
import { ReactComponent as FuelRegular } from '../assets/images/mercedes/icons/fuel-2.svg';
import { ReactComponent as FuelBold } from '../assets/images/mercedes/icons/fuel-3.svg';
import { ReactComponent as HomeLight } from '../assets/images/mercedes/icons/home-1.svg';
import { ReactComponent as HomeRegular } from '../assets/images/mercedes/icons/home-2.svg';
import { ReactComponent as HomeBold } from '../assets/images/mercedes/icons/home-3.svg';
import { ReactComponent as NavLight } from '../assets/images/mercedes/icons/nav-1.svg';
import { ReactComponent as NavRegular } from '../assets/images/mercedes/icons/nav-2.svg';
import { ReactComponent as NavBold } from '../assets/images/mercedes/icons/nav-3.svg';
import { ReactComponent as PhoneLight } from '../assets/images/mercedes/icons/phone-1.svg';
import { ReactComponent as PhoneRegular } from '../assets/images/mercedes/icons/phone-2.svg';
import { ReactComponent as PhoneBold } from '../assets/images/mercedes/icons/phone-3.svg';
import { ReactComponent as TankLight } from '../assets/images/mercedes/icons/tank-1.svg';
import { ReactComponent as TankRegular } from '../assets/images/mercedes/icons/tank-2.svg';
import { ReactComponent as TankBold } from '../assets/images/mercedes/icons/tank-3.svg';

const BlockIconViewer = () => {
  const [size, setSize] = useState('large');
  const [currentIcon, setCurrentIcon] = useState('energy');
  const [currentIconComponents, setCurrentIconComponents] = useState([]);
  const sizeList = [
    {
      id: 2,
      type: 'large',
    },

    {
      id: 1,
      type: 'medium',
    },
    {
      id: 0,
      type: 'small',
    },
  ];

  const iconList = [
    {
      name: 'energy',
      icons: [EnergyLight, EnergyRegular, EnergyBold],
    },
    {
      name: 'fuel',
      icons: [FuelLight, FuelRegular, FuelBold],
    },

    {
      name: 'home',
      icons: [HomeLight, HomeRegular, HomeBold],
    },
    {
      name: 'nav',
      icons: [NavLight, NavRegular, NavBold],
    },
    {
      name: 'phone',
      icons: [PhoneLight, PhoneRegular, PhoneBold],
    },
    {
      name: 'tank',
      icons: [TankLight, TankRegular, TankBold],
    },
  ];

  useEffect(() => {
    const current = iconList.filter((icon) => icon.name === currentIcon);
    const currentSize = sizeList.filter((sizeItem) => sizeItem.type === size);
    setCurrentIconComponents([head(current).icons[head(currentSize).id]]);
  }, [currentIcon, size]);

  return (
    <section className="section section-icons">
      <div className="section-icons__wrap">
        <div className="section-icons__col">
          <div className="section-icons__tab-section">
            {sizeList.length && (
              <ul className="section-icons__category-types" role="tablist">
                {sizeList.map(({ id, type }) => (
                  <li
                    role="tab"
                    tabIndex="0"
                    className={`section-icons__type section-icons__type ${
                      size === type ? 'section-icons__type--is-active' : ''
                    }`}
                    aria-selected={size === type ? 'true' : 'false'}
                    // aria-controls={`tab-section-${sectionId}-${index}`}
                    onClick={() => setSize(type)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 || e.keyCode === 32) {
                        e.preventDefault();
                        setSize(type);
                      }
                    }}
                    key={`tab-${id}`}
                  >
                    {type}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <FlipMove
            appearAnimation="fade"
            enterAnimation="fade"
            leaveAnimation="fade"
            className="section-icons__preview"
          >
            {currentIconComponents.map((currentIcon) => (
              <div key={currentIcon}> {currentIcon()} </div>
            ))}
          </FlipMove>
        </div>
        <div className="section-icons__selector">
          <p className="section-icons__title">Responsive icons</p>
          {iconList.length && (
            <ul className="section-icons__selector-icons" role="tablist">
              {iconList &&
                iconList.length > 0 &&
                iconList.map((item, i) => {
                  const IconSlide = item.icons[1];
                  return (
                    <li
                      onClick={() => setCurrentIcon(item.name)}
                      className={`section-icons__selector-icon ${
                        item.name === currentIcon ? 'section-icons__selector-icon--is-active' : ''
                      }`}
                    >
                      <IconSlide />
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
      </div>
    </section>
  );
};

export default BlockIconViewer;
